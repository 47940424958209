import { forwardRef } from 'react'
import { Select } from '.'
import { FIELD_NAMES_DEFAULT } from './constants'
import { URL_ALL_REGIONS } from '../../api/urrp/constants'
import { useGetDictionary } from '../../api/urrp/use-get-dictionary'

export const RegionsSelect = forwardRef((props, ref) => {
  const { data } = useGetDictionary(URL_ALL_REGIONS)

  const filterOption = (input, option) =>
    option?.[FIELD_NAMES_DEFAULT.label]
      ?.toLowerCase()
      ?.includes(input.toLowerCase())
  return (
    <Select
      showSearch
      fieldNames={FIELD_NAMES_DEFAULT}
      style={{ width: '100%' }}
      filterOption={filterOption}
      options={data}
      {...props}
      ref={ref}
    />
  )
})
