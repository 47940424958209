import { DeleteOutlined } from '@ant-design/icons'
import { Col, Collapse, Form, Row, Space } from 'antd'
import { ManageFamilyMemberButton } from '../../../components/button/manage-family-member-button'
import { COLORS } from '../../../helpers/constants'
import { familyData } from '../constants'
import { Input } from '../../../components/input'
import { Datepicker } from '../../../components/datepicker'

import { createOptionsFromArray } from '../../../helpers/constants'

import { URL_GET_VULNERABILITY } from '../../../api/urrp/constants'
import { useGetDictionary } from '../../../api/urrp/use-get-dictionary'
import { ArmenianInput } from '../../../components/input/armenian-input'
import { Select } from '../../../components/select'

const { Panel } = Collapse
export const FamilyMemberForm = ({
  field,
  remove,
  relativeTypes,
  educationTypes,
}) => {
  const removeFamilyMember = () => {
    remove(field.name)
  }

  const { data: vulnerability } = useGetDictionary(URL_GET_VULNERABILITY)

  const name = Form.useWatch([familyData, 'familyMembers', field.name, 'name'])
  const surname = Form.useWatch([
    familyData,
    'familyMembers',
    field.name,
    'surname',
  ])
  return (
    <Collapse defaultActiveKey={[field.name]} key={field.name}>
      <Panel
        header={
          name && surname ? `${name} ${surname}` : 'Ընտանիքի անդամի տվյալներ'
        }
        key={field.name}
        extra={
          field.name !== 0 && (
            <Space>
              <ManageFamilyMemberButton
                text="Հեռացնել"
                icon={<DeleteOutlined />}
                style={{ color: COLORS.ALERT.RED }}
                doAction={removeFamilyMember}
              />
            </Space>
          )
        }
      >
        <Row gutter={[12, 12]}>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label="Անուն"
              rules={[{ required: true }]}
              name={[field.name, 'name']}
            >
              <ArmenianInput />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label="Ազգանուն"
              rules={[{ required: true }]}
              name={[field.name, 'surname']}
            >
              <ArmenianInput />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label="Ծննդյան թիվ"
              rules={[{ required: true }]}
              name={[field.name, 'birthdate']}
            >
              <Datepicker
                format="DD/MM/YYYY"
                style={{ borderRadius: 10, width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[0, 12]}>
          <Col span={24}>
            <Form.Item
              name={[field.name, 'relationToMainMember']}
              label="Կապը դիմողի հետ"
              rules={[{ required: true }]}
            >
              <Select
                options={createOptionsFromArray(relativeTypes || [])}
                labelInValue
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={[field.name, 'education']}
              label="Կրթություն"
              rules={[{ required: true }]}
            >
              <Select
                options={createOptionsFromArray(educationTypes)}
                labelInValue
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={[field.name, 'workHistory']}
              label="Աշխատանքային ստաժ՝ վերջին 3 տարվա ընթացքում"
            >
              <Input />
            </Form.Item>
          </Col>
          <Form.Item name={[field.name, 'ssn']} hidden initialValue={null} />
          <Col span={24}>
            <Form.Item
              name={[field.name, 'physicalIssue']}
              label="Առողջական խնդիրներ"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={[field.name, 'mentalIssue']}
              label="Հոգեբանական խնդիրներ"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={[field.name, 'vulnerability']}
              label="Արդյո՞ք դուք կամ Ձեր ընտանիքի անդամներից որևէ մեկն ունեք նշված սոցիալական կարգավիճակներից"
              rules={[{ required: true }]}
            >
              <Select
                options={createOptionsFromArray(vulnerability || [])}
                labelInValue
              />
            </Form.Item>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  )
}
