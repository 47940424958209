import { Skeleton } from 'antd'
import { forwardRef } from 'react'
import { Select } from '.'

import { FIELD_NAMES_DEFAULT } from './constants'
import { useGetDictionary } from '../../api/urrp/use-get-dictionary'
import { URL_COMMUNITY_BY_REGION_ID } from '../../api/urrp/constants'

export const CommunitySelect = forwardRef(({ regionId, ...props }, ref) => {
  const { data, isInitialLoading } = useGetDictionary(
    URL_COMMUNITY_BY_REGION_ID,
    { regionId: regionId },
    { enabled: !!regionId },
  )
  if (isInitialLoading) {
    return <Skeleton.Input active size="large" block />
  }

  const filterOption = (input, option) =>
    option?.[FIELD_NAMES_DEFAULT.label]
      ?.toLowerCase()
      ?.includes(input.toLowerCase())

  return (
    <Select
      showSearch
      fieldNames={FIELD_NAMES_DEFAULT}
      filterOption={filterOption}
      style={{ width: '100%' }}
      options={data}
      {...props}
      ref={ref}
    />
  )
})
